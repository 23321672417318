<template>
  <div class="history grey-bg">
    <van-tabs  v-model:active="activeName" bind:change="54"  title-active-color="#FF6B29" title-inactive-color="#4D4D4D" line-width="70" line-height="3" @click="onchange">
      <van-tab :title="tab.title" :name="tab.name" v-for="(tab,index) in tabBar" :key="index">
        <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="accountsData.length > 20 ? '没有更多了' : '' "
            @load="onLoad"
            :immediate-check="false"
        >
          <!--积分记录开始-->
          <div class="historical-points">
            <div class="record">
              <div class="record-title">当前{{ tab.name === 'experience' ? '经验值' : '积分' }}</div>
              <div class="record-num" v-if="tab.name === 'experience'">{{ $handle.tranNumber(userInfo.experience,3) }}<span>经验值</span> </div>
              <div class="record-num" v-else>{{ $handle.tranNumber(userInfo.credit,3) }}<span>积分</span> </div>
            </div>

            <History :accounts="accountsData" :type="tab.name"></History>
          </div>
        </van-list>
        <!--积分记录结束-->
      </van-tab>
    </van-tabs>
    <van-toast id="van-toast"/>
  </div>
</template>

<script>
import History from "components/History";
export default {
  name: "history",
  data(){
    return{
      activeName: 'credit',    //当前选中的状态
      accounts: {},                   //历史记录
      accountsData:[],
      tabBar:[
        {name:"credit",title: "积分记录"},
        {name:"experience",title: "经验值记录"},
      ],
      loading: false,
      finished: false,
      page: 1,
    }
  },
  created() {
    if (this.$route.query.type === "amount_credit"){
      this.activeName = 'credit'
    }else if (this.$route.query.type === "experience"){
      this.activeName = 'experience'
    }
    this.getAccounts(this.activeName)
  },
    mounted() {
        this.$nextTick(()=>{
            this.$util.domMinHeight()
        })
    },
  methods:{
    // 获取历史记录
    getAccounts(type){
      let _this = this
      // _this.accounts={}
      let url = _this.$api.Accounts+`?type=${type}&page=${_this.page}`
      _this.finished = true;
      _this.$http.get(url,true).then( res => {
        if(res.data.success){
          _this.loading = false;
          if(res.data.data.data.length){
            _this.finished = false;
            _this.accounts = res.data.data
            _this.accountsData = _this.accountsData.concat(res.data.data.data)
          }else {
            _this.finished = true;
          }
        }
      } )
    },
    // 切换tab
    onchange(value){
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration:1000
      });
      this.page = 1
      this.accountsData = []
      this.activeName = value
      this.getAccounts(value)
        this.$user.getUserInfo();
    },
    onLoad(){
      this.page ++;
      this.getAccounts(this.activeName)
    }
  },
  watch:{
    userInfo(val, oldVal){
      return val;
    }
  },
  computed:{
    userInfo(){
      return this.$store.getters.userInfo;
    }
  },
  components:{
    History
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/history/history.scss";
</style>