<template>

    <div class="history-con">
        <NoData v-if="accountsData.length === 0" :bottomBg="false"></NoData>

        <div class="history-item" v-for="item in accountsData" :key="item.id">
            <div class="history-operation">
                <div class="operation-title white-space">{{ item.remark && item.remark.action }}</div>
                <span class="operation-num" :class="[item.mode === 'increase' ? '' : 'reduce']">{{ item.mode === "increase" ? '+' : '-' }}{{ $handle.tranNumber(item.amount,3) }}{{ type=== 'credit' ? '积分' : '经验值' }}</span>
            </div>
            <div class="history-date">
                <span class="history-time">{{ item.created_at }}</span>
                <span class="history-record">当前{{ type=== 'credit' ? '积分' : '经验值' }}：{{ item.total_amount }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import NoData from "components/NoData";
export default {
    name: "History",
    props:['accounts','type'],
    data(){
        return {
            accountsData:[]
        }
    },
    created() {
        // if (this.accounts.length) {
        //     this.studyList = this.study
        // }
    },
    watch:{
        accounts(val, oldValue) {
            this.accountsData = []
            this.accountsData = val
        }
    },
    components:{
        NoData
    }
}
</script>

<style scoped lang="scss">
$r: 750/16/1rem;
.history-con{
    margin-top: 20/$r;
    background: #FFF;
    .history-item{
        border-top: 1px solid #F1F1F1;
        padding: 28/$r 0 30/$r 0;
        margin: 0 35/$r;
        .history-operation{
            display: flex;
            justify-content: space-between;
            .operation-title{
                font-size: 28/$r;
                font-weight: 400;
                color: #333333;
            }
            .operation-num{
                display: flex;
                justify-content: flex-end;
                min-width: 120/$r;
                margin-left: 20/$r;
                font-size: 24/$r;
                color: #FF6B29;
            }
            .reduce{
                color: #22AC38;
            }
        }
        .history-date{
            display: flex;
            justify-content: space-between;
            margin-top: 28/$r;
            font-size: 24/$r;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
        }
    }
    &:first-child{
        border-top: none;
    }
}
</style>